<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup">
                <div class="h-handle-button">
                    <div class="h-b"><el-button type="primary" size="small" @click="batchsetwarnvalue" v-right-code="'Customer_Right_Stock_StockCompareItem_EditStockCompareItem'">批量处理</el-button></div>
                </div>
            </div>
            <el-row slot="reserve1" class="h-table-choose">
                <el-button i type="text" @click="showlist(0)" v-bind:class="0==queryParam.PageParams.status? 'activebtn':''">全部（{{SearchCountData.TotalCount}}）</el-button>
                <el-button type="text" @click="showlist(1)" v-bind:class="1==queryParam.PageParams.status? 'activebtn':''">待处理（{{SearchCountData.ToDoCount}}）</el-button>
                <el-button type="text" @click="showlist(2)" v-bind:class="2==queryParam.PageParams.status? 'activebtn':''">已处理调整（{{SearchCountData.AdjustCount}}）</el-button>
                <el-button type="text" @click="showlist(3)" v-bind:class="3==queryParam.PageParams.status? 'activebtn':''">已处理不调整（{{SearchCountData.NoAdjustCount}}）</el-button>
            </el-row>
            <p slot="elList">
                <el-table class="tb-edit" slot="elList" ref="fullwarehousewarnListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" @current-change="handleCurrentChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay "></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col,ignoreField)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <el-row>
                                <span>  {{ scope.row[col.FieldName] }}</span>
                            </el-row>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
        <el-dialog title="批量处理" :visible.sync="BatchSetWarnTemp.visible">
            <el-form label-width="80px">
                <el-form-item label="处理方式:">
                    <el-radio-group v-model="HandleStatus">
                        <el-radio :label="2">调整</el-radio>
                        <el-radio :label="3">不调整</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="处理备注:">
                    <el-input type="textarea"
                              :rows="5"
                              v-model="BatchSetWarnData.HandleRemarks">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="BatchSetWarnTemp.visible = false">取 消</el-button>
                <el-button type="primary" @click="savewarnset">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                var _this = this;
                this.Event.$on("onRefreshList", () => this.initialize());
            });

            this.initialize();
        },
        data() {
            return {
                HandleStatus: 2,
                ignoreField: [],
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    PageParams: {
                        status: 0  //默认代表全部
                    }
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                warehouselist: [],
                warnconfigdata: {
                    WarnID: '',
                    ProductNo: '',
                    InventoryType: '',
                    WarehouseNo: '',
                    StockUpperLimit: null,
                    StockLowerLimit: null
                },
                BatchSetWarnTemp: {
                    visible: false,
                    size: 'small',
                },
                BatchSetWarnData: {
                    CompareIDs: [],
                    HandleStatus: 2,
                    HandleRemarks: null
                },
                SearchCountData: {
                    TotalCount: 0,
                    ToDoCount: 0,
                    AdjustCount: 0,
                    UpperWarnCount: 0
                }
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            savewarnset() {
                var _this = this;
                _this.BatchSetWarnData.HandleStatus = _this.HandleStatus;
                _this.$ajax.send("omsapi/stockcompareitem/stockverifyhandle", "post", _this.BatchSetWarnData, (data) => {
                    if (data.IsSuccess == true) {
                        _this.Utils.messageBox("批量设置成功.", "success");
                        _this.BatchSetWarnTemp.visible = false;
                        _this.reloadPageList();
                    }
                    else {
                        this.Utils.messageBox(data.OperationDesc);
                    }
                });
            },
            batchsetwarnvalue: function () {
                var _this = this;
                if (this.multipleSelection.length <= 0) {
                    this.Utils.messageBox("请至少选中一个库存差异设置");
                    return;
                }
                _this.BatchSetWarnData = {};
                var arr = [];
                for (var i = 0; i < this.multipleSelection.length; i++) {
                    arr.push( this.multipleSelection[i].CompareID);

                }
                _this.BatchSetWarnData.CompareIDs = arr;
                _this.BatchSetWarnTemp.visible = true;
            },
            onSetOk() {

            },
            showlist: function (index) {
                this.queryParam.PageParams.status = index;
                this.reloadPageList();
            },
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
                this.getsearchcountdata(this.queryParam);
            },
            handleCurrentChange: function (val) {
                this.$emit("onSelectedRow", val);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            //获取列表
            onPageChange(param) {
                console.log("参数数据:"+JSON.stringify(param));
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/stockcompareitem/getlist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            //获取条数
            getsearchcountdata(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/stockcompareitem/getnumberbywarehouseno", "post", param, data => {
                    _this.SearchCountData = data.Result;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            }
        }
    }
</script>
<style>
</style>